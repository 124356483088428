import React from "react";
import {
  PageLayout,
  AnimatedSelector,
  BackgroundImages,
  MarkdownContent,
  OutboundLink,
  FishermanIcon,
  Section,
  MultipleComponentIterator,
  SlideReveal,
  BackgroundImageTeamMember,
  InternalLink,
} from "@bluefin/components";
import { Header, Button, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { createTeamMemberSlug } from "@bluefin/gatsby-plugin-fisherman-page-creator";

export default class TeamPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { bookingUrl, allFishermanBusinessTeam } = data;

    return (
      <WebsiteLayout>
        <PageLayout hero={false} className={"team-page"}>
          <AnimatedSelector
            targetSelector={
              ".image.background::before, .image.background::after"
            }
            duration={1500}
            transitionType={"zoom"}
            transitionProps={{
              animating: { scale: 1 },
              preAnimating: { scale: 1.4 },
              serverSide: { scale: 1.4 },
            }}
            easing={"cubic-bezier(0,1,0.99,1)"}
            runPolicy={"RUN_ONCE_EVERY_VISIT"}
            animationKey={"team-hero-animation"}
          >
            <BackgroundImages
              parentClassName={"hero-container"}
              imageClassName={"hero-image overlay"}
              carouselProps={{
                leftArrowIcon: "fancy-arrow-left",
                rightArrowIcon: "fancy-arrow-right",
              }}
              imageProps={{ loadStrategy: "eager" }}
            >
              <Header as={"h1"} />
              <MarkdownContent />
              <div className={"ctas-container"}>
                <Button
                  primary={true}
                  as={OutboundLink}
                  to={bookingUrl.url}
                  event={{
                    category: "Booking",
                    action: "Schedule Appointment Intent",
                  }}
                  icon={<FishermanIcon iconName={"fancy-arrow-right"} />}
                  labelPosition={"right"}
                  content={bookingUrl.title}
                />
              </div>
            </BackgroundImages>
          </AnimatedSelector>
          <Section className={"team-content-container"}>
            <Card.Group itemsPerRow={3} className={"stylists"}>
              <MultipleComponentIterator
                iterator={allFishermanBusinessTeam.nodes}
                components={[
                  {
                    component: (
                      <SlideReveal
                        className={"ui card team-member"}
                        fade={true}
                        direction={"up"}
                        triggerOnce={true}
                      />
                    ),
                    propMap: { key: "_id" },
                    children: [
                      {
                        component: (
                          <BackgroundImageTeamMember
                            useModal={false}
                            as={InternalLink}
                            moreInfoIconName={"fancy-arrow-right"}
                          />
                        ),
                        propMap: {
                          "teamMember.name": "name",
                          "teamMember.role": "role",
                          "teamMember.photo": "gatsbyImage",
                          "teamMember.description": "description",
                          "teamMember.email": "email",
                          "teamMember.phone": "phone",
                          "teamMember.locations": "locations",
                          "teamMember.hours": "hours",
                          "teamMember.social": "socialMedia",
                          to: createTeamMemberSlug,
                        },
                      },
                    ],
                  },
                ]}
              />
            </Card.Group>
          </Section>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    bookingUrl: fishermanBusinessWebsitePage(
      title: { regex: "/Book Now|Make An Appointment/" }
      pageType: { eq: "External" }
    ) {
      url
      title
    }
    allFishermanBusinessTeam(
      sort: { order: ASC, fields: order }
      filter: { show: { eq: true } }
    ) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
